import React, { useEffect, useRef, useState } from 'react';
import styles from './style.scss';
import clsx from 'clsx';
import { ArrowFilledIcon } from '../../../svg/icons/ArrowFilled';

interface Props {
  title: string;
  children: React.ReactNode;
  className?: string;
  contentClassName?: string;
  opened?: boolean;
  setOpened?: (value: boolean) => void;
}

/**
 * This component is pretty complex. It uses multiple states + resizeObserver
 * It requires additional transitioning state cause in case children start to resize -
 * ResizeObserver catches it, and it's triggered every single tick. Meaning if we animate
 * height change all the time - we'll have a double delay mixed up with childrens animation delay
 *
 * Due to this - I enable height animation only and only when it's opened or closed.
 * For children height change resize observer handles it itself
 * */

export const MobileCollapsibleSection = ({
  title,
  children,
  className,
  contentClassName,
  opened: externalOpened = false,
  setOpened: setExternalOpened,
}: Props) => {
  const [opened, setOpened] = useState(false);
  const [transitioning, setTransitioning] = useState(false);
  const [height, setHeight] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  // connecting with outer state
  useEffect(() => {
    setOpened(externalOpened);
    doTransition();
  }, [externalOpened]);

  // connecting with outer state
  useEffect(() => {
    if (setExternalOpened) {
      setExternalOpened(opened);
    }
  }, [opened]);

  const handleHeaderClick = () => {
    setOpened((prev) => !prev);
    doTransition();
  };

  const doTransition = () => {
    setTransitioning(true);
    setTimeout(() => {
      setTransitioning(false);
    }, 300);
  };

  // Observer hook
  useEffect(() => {
    if (!ref.current) return;
    const resizeObserver = new ResizeObserver(() => {
      updateHeight();
    });
    resizeObserver.observe(ref.current);
    return () => resizeObserver.disconnect(); // clean up
  }, []);

  const updateHeight = () => {
    setHeight(ref.current ? ref.current.scrollHeight : 1000);
  };

  return (
    <div
      className={clsx(
        styles.mobileCollapsibleSection,
        opened && styles.opened,
        className,
      )}
    >
      <div className={styles.header} onClick={handleHeaderClick}>
        <ArrowFilledIcon className={styles.arrow} />
        <div className={styles.text}>{title}</div>
      </div>
      <div
        className={clsx(
          styles.collapsible,
          transitioning && styles.transitioning,
        )}
        style={opened ? { maxHeight: height } : undefined}
      >
        <div className={clsx(styles.content, contentClassName)} ref={ref}>
          {children}
        </div>
      </div>
    </div>
  );
};
