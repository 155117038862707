import React, { useEffect, useMemo, useRef, useState } from 'react';
import styles from './style.scss';
import { TextFileIcon } from '../../../svg/icons/TextFile';
import { FolderIcon } from '../../../svg/icons/Folder';
import { ArrowOutlineIcon } from '../../../svg/icons/ArrowOutline';
import clsx from 'clsx';
import { AboutStructureLine, FileLine, FolderLine } from '../utils';
import { storage } from '../../../../utils/localStorage';

interface AboutFileStructureProps {
  lines: AboutStructureLine[];
  selectedFile: FileLine | null;
  setSelectedFile: (file: FileLine) => void;
  onFolderHeightChange?: (delta: number) => void;
}

export const AboutFileStructure = ({
  lines,
  selectedFile,
  setSelectedFile,
  onFolderHeightChange,
}: AboutFileStructureProps) => {
  return (
    <>
      {lines.map((el, i) => {
        if (el.type === 'file') {
          return (
            <FileLine
              title={el.title}
              active={el.id === (selectedFile?.id || 'nonsence')}
              onClick={() => setSelectedFile(el)}
              key={i}
            />
          );
        }
        return (
          <FolderLine
            data={el}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            onHeightChange={onFolderHeightChange || (() => {})}
            key={el.id}
          />
        );
      })}
    </>
  );
};

interface FolderLineProps {
  data: FolderLine;
  selectedFile: FileLine | null;
  setSelectedFile: (file: FileLine) => void;
  onHeightChange: (deltaHeight: number) => void;
}

const FolderLine = ({
  data,
  selectedFile,
  setSelectedFile,
  onHeightChange,
}: FolderLineProps) => {
  const firstTime = useRef(true);
  const [opened, setOpened] = useState(data.opened);
  const ownRef = useRef<HTMLDivElement>(null);

  // ownHeight is a constant, knows folder insides height without nested  elements
  const ownHeight = useRef(0);
  // nestedHeight knows about nested folders additional height
  const [nestedHeight, setNestedHeight] = useState(0);

  useEffect(() => {
    if (firstTime.current) {
      firstTime.current = false;
      ownHeight.current = ownRef.current ? ownRef.current.scrollHeight : 1000;
      if (opened) onHeightChange(ownHeight.current);
    } else {
      if (opened) {
        storage.handleFolderOpen(data.id);
      } else {
        storage.handleFolderClose(data.id);
      }
    }
  }, [opened]);

  const handleClick = () => {
    if (!haveInsides) return;
    const prev = opened;
    setOpened(!prev);
    // passing change of height for parent older (maxHeight - insides themselves, nestedHeight - nested in insides)
    onHeightChange(
      prev
        ? -ownHeight.current - nestedHeight
        : ownHeight.current + nestedHeight,
    );
  };

  const handleNestedHeightChange = (delta: number) => {
    setNestedHeight((prev) => prev + delta);
  };

  const haveInsides = useMemo(() => {
    return Object.keys(data.insides).length > 0;
  }, [data.insides]);

  return (
    <>
      <div
        className={clsx(styles.structureLine, opened && styles.opened)}
        onClick={handleClick}
      >
        {haveInsides && <ArrowOutlineIcon className={styles.openSelector} />}
        <FolderIcon className={styles.iconFile} color={data.color} />
        <h5 className={styles.text}>{data.title}</h5>
      </div>
      {haveInsides && (
        <div
          className={styles.nestedStructure}
          ref={ownRef}
          style={
            opened ? { maxHeight: ownHeight.current + nestedHeight } : undefined
          }
        >
          <AboutFileStructure
            lines={data.insides}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            onFolderHeightChange={handleNestedHeightChange}
          />
        </div>
      )}
    </>
  );
};

interface FileLineProps {
  title: string;
  onClick: () => void;
  active: boolean;
}

const FileLine = ({ title, onClick, active }: FileLineProps) => (
  <div
    className={clsx(styles.structureLine, active && styles.active)}
    onClick={onClick}
  >
    <TextFileIcon className={styles.iconFile} />
    <h5 className={styles.text}>{title}</h5>
  </div>
);
