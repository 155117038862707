import React from 'react';
import { SvgProps } from '../SvgProps';
import { appColors } from '../../../utils/constants';

export const ArrowFilledIcon = ({
  className,
  color = appColors.textActive,
  onClick,
}: SvgProps) => (
  <svg
    width='9'
    height='7'
    viewBox='0 0 9 7'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    fill={color}
    onClick={onClick}
  >
    <path d='M4.74998 6.65186L0.499969 0.651856L9 0.651855L4.74998 6.65186Z' />
  </svg>
);
