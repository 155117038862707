import { ReactIcon } from '../../svg/icons/React';
import { CssIcon } from '../../svg/icons/Css';
import { CodeIcon } from '../../svg/icons/Code';
import { JavaScriptIcon } from '../../svg/icons/JS';
import { TypeScriptIcon } from '../../svg/icons/TS';
import { SolidityIcon } from '../../svg/icons/Solidity';
import { EthereumIcon } from '../../svg/icons/Ethereum';
import { ArrayOfSize } from '../../../utils/stuff';
import { appColors } from '../../../utils/constants';

export type ProjectTag = { id: string; title: string; icon: typeof ReactIcon };

const projectsTags = {
  react: { title: 'React', icon: ReactIcon, id: 'react' },
  node: { title: 'Node.js', icon: CodeIcon, id: 'node' },
  ts: { title: 'TypeScript', icon: TypeScriptIcon, id: 'ts' },
  css: { title: 'CSS', icon: CssIcon, id: 'css' },
  js: { title: 'JavaScript', icon: JavaScriptIcon, id: 'js' },
  web3: { title: 'web3.js', icon: EthereumIcon, id: 'web3' },
  solidity: { title: 'Solidity', icon: SolidityIcon, id: 'solidity' },
};

export const projectsTagsAsArray: ProjectTag[] = Object.values(projectsTags);

type StringBlock = {
  type: 'text' | 'header';
  value: string;
};

type ImageBlock = {
  type: 'image';
  url: string;
  label: string;
};

type ListBlock = {
  type: 'list';
  value: string[];
};

type DescriptionBlock = StringBlock | ImageBlock | ListBlock;

export type ProjectDescription = DescriptionBlock[];

type ProjectLink = {
  url: string;
  title: string;
};

export type Project = {
  number: number;
  name: string;
  fullName: string;
  disclaimer: string;
  description: ProjectDescription;
  liveLink?: string;
  footerLinks?: ProjectLink[];
  tagsIds: string[];
  image: string;
  colors: ArrayOfSize<string, 2>;
  url: string;
};

// TODO:: shuffle properly. Ideally colors should always change

const rawProjects: Omit<Project, 'number'>[] = [
  {
    name: '_nifty-hands',
    fullName: 'Nifty Hands',
    liveLink: 'https://nifty-hands.com',
    disclaimer: 'Blockchain based rock-paper-scissors game',
    description: [
      {
        type: 'header',
        value: 'Project overview',
      },
      {
        type: 'text',
        value: `Nifty Hands is a blockchain-powered Rock-Paper-Scrissors game project with a straightforward yet 
        captivating concept. %%br%% %%br%% 
        Users have the ability to mint NFT hands, which can then engage in 3D rock-paper-scissors battles against the 
        hands of other users. These matches include both friendly free battles and high-stakes encounters with wagers.`,
      },
      {
        type: 'header',
        value: 'My role',
      },
      {
        type: 'text',
        value: `Originating as a freelance contract, this project involved a collaborative effort. The contractor 
        provided the designs and managed the marketing aspect, while I focused on the technical facets. I 
        independently crafted the entire platform, including backend, frontend, and smart contract development.`,
      },
      {
        type: 'header',
        value: 'Frontend part',
      },
      {
        type: 'text',
        value: `Nifty Hands comprises several functional modules: an NFT marketplace, buy/sell interfaces for $CREAM 
        (internal currency), and a fight module. Except for the fight module, all are closely integrated with the 
        Ethereum blockchain.`,
      },
      {
        type: 'image',
        url: 'assets/projects/niftyHands/marketplace.jpeg',
        label: 'Hands marketplace',
      },
      {
        type: 'text',
        value: `User authorization relies on cryptographic signatures via Metamask, and purchases in the marketplace 
        result in direct NFT minting to the user's Metamask wallet.`,
      },
      {
        type: 'image',
        url: 'assets/projects/niftyHands/fight.jpeg',
        label: 'Fight',
      },
      {
        type: 'text',
        value: `The fight module is constructed using the 3D library Three.js, ensuring a visually engaging experience. 
        In the interest of interaction, security, and preventing cheating, all game moves are processed on the backend. 
        To minimize latency, the fight module employs the websocket protocol for swift communication with the backend.`,
      },
      {
        type: 'header',
        value: 'Web3 work',
      },
      {
        type: 'text',
        value: `At its core, the application relies on the Ethereum blockchain for transparency and trust. I created a 
        smart contract to oversee all marketplace operations, including NFT minting, secondary sales, and the 
        implementation of promotional codes.`,
      },
      {
        type: 'image',
        url: 'assets/projects/niftyHands/opensea.png',
        label: 'Opensea collection',
      },
      {
        type: 'text',
        value: `$CREAM transactions are straightforward Ethereum transactions, while the $CREAM balance is maintained 
        in a centralized database. This approach was chosen to minimize gas costs during high-stakes fights with wagers.`,
      },
      {
        type: 'header',
        value: 'Backend logic',
      },
      {
        type: 'text',
        value: `The backend acts as the project's core and is crucial for maintaining security. It thoroughly checks 
        each transaction made by users to ensure no one can manipulate the system. %%br%% %%br%%
        Within the backend, there's a specific websocket module dedicated to managing the fights. This module is 
        equipped with strong anti-cheat system, battle-match system, and it's capable of handling various situations, 
        up to power outages for one or both players.`,
      },
    ],
    footerLinks: [
      { url: 'https://nifty-hands.com', title: 'explore-live' },
      {
        url: 'https://opensea.io/collection/nifty-hands',
        title: 'opensea-collection',
      },
    ],
    tagsIds: [
      projectsTags.react.id,
      projectsTags.node.id,
      projectsTags.ts.id,
      projectsTags.css.id,
      projectsTags.web3.id,
      projectsTags.solidity.id,
    ],
    image: 'assets/projects/niftyHands/cover.jpg',
    colors: ['#FF009C', '#482893'],
    url: 'nifty-hands',
  },
  {
    name: '_crypto-chapel',
    fullName: 'City Hall Of Love',
    disclaimer: 'Metaverse chapel. First blockchain project',
    description: [
      {
        type: 'header',
        value: 'Project overview',
      },
      {
        type: 'text',
        value: `The City Hall of Love is a virtual sanctuary where couples can come together in a digital union, 
        complete with an NFT marriage certificate, followed by joyous celebrations. This project opens the door to 
        marriage for those who face legal constraints, ensuring that love knows no bounds or barriers.`,
      },
      {
        type: 'header',
        value: 'Metaverse frontend',
      },
      {
        type: 'image',
        url: 'assets/projects/closeup/overview.png',
        label: 'Celebration in chapel',
      },
      {
        type: 'text',
        value: `The scene was constructed in the Decentraland metaverse using the Decentraland SDK, with 3D models 
        supplied by the talented 3D artist.`,
      },
      {
        type: 'header',
        value: 'Smart Contract',
      },
      {
        type: 'image',
        url: 'assets/projects/closeup/opensea.png',
        label: 'Opensea collection',
      },
      {
        type: 'text',
        value: `I designed a straightforward Ethereum smart contract for minting certificates. Upon one participant 
        completing the minting process, both participants receive a shared NFT certificate in their respective wallets`,
      },
      {
        type: 'header',
        value: 'Backend',
      },
      {
        type: 'text',
        value: `I developed a backend server, with help of decentraland authorization module. The server validates data 
        retrieved from the API by cross-referencing it with Ethereum contract data.`,
      },
      {
        type: 'image',
        url: 'assets/projects/closeup/cert.png',
        label: 'Marriage certificate',
      },
      {
        type: 'text',
        value: `Once contract transaction is completed and users have minted their tokens - the server generates 
        certificate image, and from here on users are considered virtually married`,
      },
    ],
    tagsIds: [
      projectsTags.node.id,
      projectsTags.ts.id,
      projectsTags.web3.id,
      projectsTags.solidity.id,
    ],
    image: 'assets/projects/closeup/cover.jpg',
    colors: ['#ff68ff', '#2b38d4'],
    url: 'crypto-chapel',
  },
  {
    name: '_run-for-your-lives',
    fullName: 'Run for your lives',
    liveLink: 'https://runforyourlives.co',
    disclaimer: 'Run for your lives landing page',
    description: [
      {
        type: 'header',
        value: 'Project overview',
      },
      {
        type: 'text',
        value: `Run for your lives is a running event, and I was tasked with developing a landing page using the 
        provided Figma designs.`,
      },
      {
        type: 'image',
        url: 'assets/projects/rfyl/firstScreen.png',
        label: 'Landing page',
      },
      {
        type: 'text',
        value: `The website's content is managed through a custom, hand-written CMS, which was 
        originally created during the 2mrlabs website project, and found it's use on this website as well.`,
      },
    ],
    footerLinks: [
      {
        url: 'https://runforyourlives.co',
        title: 'explore-live',
      },
    ],
    tagsIds: [
      projectsTags.react.id,
      projectsTags.node.id,
      projectsTags.ts.id,
      projectsTags.css.id,
    ],
    image: 'assets/projects/rfyl/cover.png',
    colors: ['#FF0000', appColors.accentBlue],
    url: 'rfyl',
  },
  {
    name: '_2mr-labs',
    fullName: '2MR Labs Website',
    liveLink: 'https://2mrlabs.com',
    disclaimer: '2MR Labs website',
    description: [
      {
        type: 'header',
        value: 'Project overview',
      },
      {
        type: 'text',
        value: `During my employment at 2MR Labs, I undertook the development of the company's website, in alignment 
        with the provided Figma design concepts. %%br%% %%br%% 
        In response to tight deadlines, I tapped into my network of developer contacts and enlisted two additional 
        experts. This collaborative effort allowed us to create the entire website in just four intense days of work.`,
      },
      {
        type: 'header',
        value: 'CMS',
      },
      {
        type: 'text',
        value: `During the development process, we made a decision to implement a content management system (CMS) for 
        the website.`,
      },
      {
        type: 'image',
        url: 'assets/projects/2mrlabs/cms.png',
        label: 'CMS home page editor',
      },
      {
        type: 'text',
        value: `While it wasn't an absolute necessity, this decision brought significant benefits, enabling 
        non-technical departments to easily make content adjustments, such as modifying text, replacing images, or 
        adding new articles, without requiring developer assistance.`,
      },
      {
        type: 'image',
        url: 'assets/projects/2mrlabs/cmsLayout.png',
        label: 'CMS article editor',
      },
    ],
    footerLinks: [
      {
        url: 'https://2mrlabs.com',
        title: 'explore-live',
      },
    ],
    tagsIds: [
      projectsTags.react.id,
      projectsTags.node.id,
      projectsTags.ts.id,
      projectsTags.css.id,
    ],
    image: 'assets/projects/2mrlabs/cover.jpeg',
    colors: ['#FFF', appColors.justBlue],
    url: '2mr',
  },
  {
    name: '_metaverse_hotel',
    fullName: 'MSocial Hotel',
    liveLink: 'https://play.decentraland.org/?position=40%2C-5',
    disclaimer: 'Virtual hotel in Decentraland metaverse',
    description: [
      {
        type: 'header',
        value: 'Project overview',
      },
      {
        type: 'text',
        value: `This project was created as a promotional campaign for Msocial Hotels, aiming to introduce the concept 
        of a metaverse hotel experience. Users can engage with a captivating NPC character, explore a guided path 
        featuring various Msocial Hotels videos, and ultimately receive real-world rewards.`,
      },
      {
        type: 'header',
        value: 'Metaverse frontend',
      },
      {
        type: 'image',
        url: 'assets/projects/msocial/scene.png',
        label: 'Inside of the hotel',
      },
      {
        type: 'text',
        value: `This project exclusively involved building a scene in Decentraland using 3D models provided by the 
        artist. While it did not require backend or smart contract work, we encountered numerous challenges arising 
        from Decentraland's limitations`,
      },
    ],
    footerLinks: [
      {
        url: 'https://play.decentraland.org/?position=40%2C-5',
        title: 'explore-live',
      },
    ],
    tagsIds: [projectsTags.ts.id],
    image: 'assets/projects/msocial/cover.png',
    colors: [appColors.justPurple, appColors.accentBlue],
    url: 'msocial',
  },
  {
    name: '_flowster',
    fullName: 'Flowster',
    liveLink: 'https://in.flowster.app',
    disclaimer: 'Business workflows automation app',
    description: [
      {
        type: 'header',
        value: 'Project overview',
      },
      {
        type: 'text',
        value: `Flowster is a platform dedicated to streamlining workflows and improving operational efficiency.%%br%% %%br%%
          As a Fullstack developer at Flowster, I played a pivotal role in enhancing the platform's user experience 
          and functionality. During my employment, I tackled a diverse range of tasks, varying from minor to highly 
          complex, a common occurrence in significant projects.`,
      },
      {
        type: 'header',
        value: 'Frontend work',
      },
      {
        type: 'text',
        value: `When I joined the team, the project was quite a mess, with UI design saying "Hello, I'm from 2000s". 
        I initiated the redesign process, and during development of new UI refactored the entire application. Or, 
        better say, rebuilt the entire application.`,
      },
      {
        type: 'image',
        url: 'assets/projects/flowster/workflow.png',
        label: 'Workflow page',
      },
      {
        type: 'text',
        value: `After all, the frontend codebase was overhauled, resulting in a cleaner code, more efficient
         codebase and of course, much nicer UX/UI (designs were provided in Figma).`,
      },
      {
        type: 'header',
        value: 'Backend work',
      },
      {
        type: 'image',
        url: 'assets/projects/flowster/library.png',
        label: 'Library page',
      },
      {
        type: 'list',
        value: [
          'Worked with BrainTree payments, including webhooks system',
          'Assisted to set up a proper ActiveCampaign integration',
          "Undertook a variety of diverse tasks contributing to Flowster's comprehensive development",
        ],
      },
      {
        type: 'header',
        value: 'Redesigned Flowster in Action',
      },
      {
        type: 'image',
        url: 'assets/projects/flowster/login.png',
        label: 'Single order page',
      },
      {
        type: 'text',
        value: `Feel free to check out flowster yourself. It seems that they the app no free plan anymore, but you can
         still access some parts of application, such as marketplace`,
      },
    ],
    footerLinks: [
      { url: 'https://in.flowster.app/', title: 'explore-app' },
      {
        url: 'https://in.flowster.app/marketplace',
        title: 'marketplace',
      },
      { url: 'https://flowster.app/', title: 'landing' },
    ],
    tagsIds: [
      projectsTags.react.id,
      projectsTags.node.id,
      projectsTags.css.id,
      projectsTags.js.id,
    ],
    image: 'assets/projects/flowster/cover.png',
    colors: ['#ff4a73', '#298cbf'],
    url: 'flowster',
  },
  {
    name: '_mac-store-demo',
    fullName: 'Mac Store Demo',
    liveLink: 'https://mac.thegreg.dev',
    disclaimer: 'First demo project made with MERN stack',
    description: [
      {
        type: 'header',
        value: 'Project overview',
      },
      {
        type: 'text',
        value: `Let me introduce one of my earlier demo projects, developed years ago.%%br%%
          No team was involved, so I had to design and build both backend and frontend from scratch. 
          Even though it's an old project, it still represents main advantages of MERN (MongoDB, Express, React and Node.js) stack`,
      },
      {
        type: 'header',
        value: 'Frontend Highlights',
      },
      {
        type: 'text',
        value: `Few responsive pages, built with React and pure SCSS. User can make an order, and later find it in CMS`,
      },
      {
        type: 'image',
        url: 'assets/projects/macStore/main_page.png',
        label: 'Main page',
      },
      {
        type: 'list',
        value: [
          'Fully responsive design',
          'Parallax background created using pure CSS',
          'Entirely built with React',
          'Interaction with the server-side via a REST API',
        ],
      },
      {
        type: 'header',
        value: 'CMS/Control Panel',
      },
      {
        type: 'text',
        value: `Everything on the website can be modified via a simple hand-written content management system (CMS). 
        Since it's a demo project, it doesn't require any authentication. All changes you make will be visible for all 
        visitors, so in 30 minutes after you perform your first change, everything will be reset`,
      },
      {
        type: 'image',
        url: 'assets/projects/macStore/cms.png',
        label: 'CMS editor',
      },
      {
        type: 'list',
        value: [
          'Everything can be adjusted with a simple CMS',
          'CMS is a separate frontend made with React',
          "Accessible by adding '/admin' after the site URL",
          'No authentication required',
          'All changes are reverted 30 minutes after the change',
          'Manual reset available through the reset button',
        ],
      },
      {
        type: 'header',
        value: 'Backend Insights',
      },
      {
        type: 'text',
        value: `All content and orders are stored in MongoDB database, and are managed by Node.js+Express server. 
        Pretty simple, yet effective. Auto-reset happens on backend side as well`,
      },
      {
        type: 'image',
        url: 'assets/projects/macStore/cms_orders.png',
        label: 'Single order page',
      },
      {
        type: 'list',
        value: [
          'A single Nodes.js+Express server',
          '2 sets of API endpoints for client and CMS frontends',
          'All website contents are stored in Mongo database',
        ],
      },
    ],
    footerLinks: [
      { url: 'https://mac.thegreg.dev', title: 'explore-live' },
      { url: 'https://mac.thegreg.dev/admin', title: 'explore-cms' },
    ],
    tagsIds: [
      projectsTags.react.id,
      projectsTags.node.id,
      projectsTags.css.id,
      projectsTags.js.id,
    ],
    image: 'assets/projects/macStore/cover.png',
    colors: [appColors.justGreen, appColors.accentBlue],
    url: 'mac-store',
  },
];

export const projects: Project[] = rawProjects.map((p, i) => ({
  ...p,
  number: i + 1,
}));
