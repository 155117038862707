import React, { useMemo } from 'react';
import styles from './style.scss';
import { Project, projectsTagsAsArray } from '../../../projects/utils';
import { SvgProps } from '../../../../svg/SvgProps';
import clsx from 'clsx';
import { Button } from '../../../../controls/button';
import useWindowSize from '../../../../../hooks/useWindowSize';

interface ProjectSliderCardProps {
  data: Project;
  opacity: number;
  cardHeight: number;
  active: boolean;
}

export const ProjectsSliderCard = ({
  data,
  opacity,
  cardHeight,
  active,
}: ProjectSliderCardProps) => {
  const [sizeX] = useWindowSize();

  // taking first 5 (or less) tags to display on project card
  const icons = useMemo<React.FunctionComponent<SvgProps>[]>(() => {
    return projectsTagsAsArray
      .filter((t) => data.tagsIds.includes(t.id))
      .map((t) => t.icon)
      .slice(0, sizeX <= 1400 ? 4 : 5);
  }, [data.tagsIds, sizeX <= 1400]);

  return (
    <div
      className={clsx(styles.projectSliderCard, active && styles.active)}
      style={{ opacity, height: cardHeight }}
    >
      <div className={styles.cardContent}>
        <div className={styles.topBlock}>
          <h2 className={styles.title}>{data.fullName}</h2>
          <p className={styles.disclaimer}>{data.disclaimer}</p>
        </div>
        <div className={styles.bottomLine}>
          <Button
            color='secondary'
            link={`/projects/${data.url}`}
            className={styles.button}
          >
            view-project
          </Button>
          <div className={styles.tags}>
            {icons.map((Icon, i) => (
              <Icon className={styles.icon} key={i} />
            ))}
          </div>
        </div>
      </div>
      <div className={styles.imageWrapper}>
        <img
          src={data.image}
          alt={`Project ${data.name} image`}
          className={styles.image}
        />
        <div className={styles.tags}>
          {icons.map((Icon, i) => (
            <Icon className={styles.icon} key={i} />
          ))}
        </div>
      </div>
    </div>
  );
};
