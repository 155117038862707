import React from 'react';
import { SvgProps } from '../SvgProps';
import { appColors } from '../../../utils/constants';

export const SolidityIcon = ({
  className,
  color = appColors.text,
  onClick,
}: SvgProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 523.9 812.97'
    fill={color}
    className={className}
    onClick={onClick}
  >
    <path d='M391.93 0l-130.7 232.3H0L130.6 0z' />
    <path d='M261.23 232.3h261.3L391.94 0H130.6z' />
    <path d='M130.6 464.5l130.63-232.2L130.6 0 0 232.3z' />
    <path d='M131.88 812.97l130.7-232.3H523.9l-130.7 232.3z' />
    <path d='M262.58 580.67H1.28l130.6 232.3H393.2z' />
    <path d='M393.2 348.45L262.58 580.67l130.62 232.3 130.7-232.3z' />
  </svg>
);
