import React, { createContext, useContext, useEffect, useRef } from 'react';
import styles from './style.scss';
import useArrayState from '../../hooks/useArrayState';
import {
  alertDebug,
  AlertTypes,
  ejectErrorMessage,
  IAlertMessage,
} from './utils';
import { AlertMessage } from './message';

type InputMessage = string | any;

interface IAlert {
  show: (message: string, delay?: number) => void;
  showError: (message: InputMessage, delay?: number) => void;
}

const AlertContext = createContext<IAlert>({
  show: () => {
    throw Error('Out of alert context scope');
  },
  showError: () => {
    throw Error('Out of alert context scope');
  },
});

interface AlertContextProviderProps {
  children: React.ReactNode;
}

export const AlertContextProvider = (props: AlertContextProviderProps) => {
  const {
    value: messages,
    push: addMessage,
    remove: removeMessage,
  } = useArrayState<IAlertMessage>(
    alertDebug.enabled ? alertDebug.messages : [],
  );
  const id = useRef(0);

  useEffect(() => {
    if (messages.length === 0) id.current = 0;
  }, [messages]);

  const commonShow = (
    message: string,
    delay: number = 3000,
    type: AlertTypes = AlertTypes.DEFAULT,
  ) => {
    addMessage({ message, delay, id: id.current, type });
    id.current++;
  };

  const show = (message: string, delay?: number) =>
    commonShow(message, delay, AlertTypes.DEFAULT);

  const showError = (message: InputMessage, delay?: number) => {
    const text = ejectErrorMessage(message);
    commonShow(text, delay, AlertTypes.ERROR);
  };

  const handleDeleteMessage = (id: number) => {
    if (alertDebug.enabled) {
      const msg = messages.find((m) => m.id === id);
      if (!msg) return;
      removeMessage((m) => m.id === id);
      addMessage({ ...msg, id: -msg.id });
    } else {
      removeMessage((m) => m.id === id);
    }
  };

  return (
    <AlertContext.Provider value={{ show, showError }}>
      {props.children}
      <div className={styles.alertMessagesWrapper}>
        {messages.map((message) => (
          <AlertMessage
            message={message}
            removeSelf={() => handleDeleteMessage(message.id)}
            key={message.id}
          />
        ))}
      </div>
    </AlertContext.Provider>
  );
};

export const useAlert = () => useContext(AlertContext);
