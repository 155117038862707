import React from 'react';
import styles from './style.scss';
import clsx from 'clsx';
import useWindowSize from '../../../../hooks/useWindowSize';

interface ContentHeaderProps {
  children: React.ReactNode;
  onClick?: () => void;
  className?: string;
  mobileBreakpoint?: number;
}

export const ContentHeader = ({
  children,
  className,
  mobileBreakpoint = 600,
  onClick,
}: ContentHeaderProps) => {
  const [sizeX] = useWindowSize();
  return (
    <div
      className={clsx(
        styles.contentHeader,
        sizeX <= mobileBreakpoint && styles.mobile,
        className,
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
