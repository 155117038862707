import React, { useMemo } from 'react';

interface BlurProps {
  colorTop: string;
  colorBottom: string;
  blur?: number;
  className?: string;
}

export const BlurBackground = ({
  className,
  colorTop,
  colorBottom,
  blur = 0,
}: BlurProps) => {
  const id = useMemo(() => String(Math.random()), []);

  return (
    <svg viewBox='0 0 1214 1130' fill='none' className={className}>
      <g filter={`url(#filter-bottom-${id}`}>
        <path
          d='M823.946 955.519L610.032 904.132L520.663 839.469L547.226 668.957L722.649 649.391L721.041 527.647L923.309 473.392L1053.602 504.691L971.165 770.797L843.892 903.987L823.946 955.519Z'
          fill={colorBottom}
        />
      </g>
      <g filter={`url(#filter-top-${id}`}>
        <path
          d='M773.469 358.482L789.984 577.861L755.759 682.726L585.295 709.598L513.003 548.57L397.588 587.343L284.059 411.368L274 277.746L552.567 274.828L623.099 385.846L773.469 358.482Z'
          fill={colorTop}
        />
      </g>
      <defs>
        <filter
          id={`filter-bottom-${id}`}
          x='346.664'
          y='299.392'
          width='880.938'
          height='830.128'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation={blur}
            result='effect1_foregroundBlur_64_2081'
          />
        </filter>
        <filter
          id={`filter-top-${id}`}
          x='0'
          y='0'
          width='1063.983'
          height='982.77'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation={blur}
            result='effect1_foregroundBlur_64_2081'
          />
        </filter>
      </defs>
    </svg>
  );
};
