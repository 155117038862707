import React from 'react';
import styles from './style.scss';
import clsx from 'clsx';
import { appColors } from '../../../../../utils/constants';

interface MenuButtonProps {
  opened: boolean;
  onClick: (e: React.MouseEvent) => void;
  className?: string;
}

export const MenuButton = ({ opened, onClick, className }: MenuButtonProps) => {
  return (
    <div
      onClick={onClick}
      className={clsx(styles.menuButtonWrapper, className)}
    >
      <div className={clsx(styles.menuButton, opened && styles.transformed)}>
        <svg
          viewBox='0 0 16 16'
          fill={appColors.text}
          xmlns='http://www.w3.org/2000/svg'
          className={styles.close}
        >
          <path d='M8 6.2225L14.2225 0L16 1.7775L9.7775 8L16 14.2225L14.2225 16L8 9.7775L1.7775 16L0 14.2225L6.2225 8L0 1.7775L1.7775 0L8 6.2225Z' />
        </svg>
        <svg viewBox='0 0 18 16' fill={appColors.text} className={styles.open}>
          <path d='M0 0H18V2H0V0ZM0 7H18V9H0V7ZM0 14H18V16H0V14Z' />
        </svg>
      </div>
    </div>
  );
};
