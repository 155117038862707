import React from 'react';
import styles from './style.scss';
import { ContentHeader } from '../../../layout/content/header';
import { ContentHeaderTab } from '../../../layout/content/headerTab';
import { ContactForm } from './contactForm';
import { ContentBody } from '../../../layout/content/body';
import { useInputState } from '../../../controls/input/utils';
import clsx from 'clsx';
import { ContactFormCode } from './contactFormCode';
import { MobileCollapsibleSection } from '../../../layout/content/mobileCollapsibleSection';
import { ContactsLeftPanel } from '../leftPanelContacts';

export const ContactPageBody = () => {
  const nameInputState = useInputState();
  const emailInputState = useInputState();
  const messageInputState = useInputState();

  return (
    <ContentBody className={styles.contactsPageBody}>
      <ContentHeader className={styles.header}>
        <ContentHeaderTab mobileBreakpoint={600}>contact-me</ContentHeaderTab>
      </ContentHeader>
      <div className={clsx(styles.column, styles.withRightBorder)}>
        <MobileCollapsibleSection
          title='contacts'
          className={styles.collapsible}
        >
          <ContactsLeftPanel mode='primary' />
        </MobileCollapsibleSection>
        <MobileCollapsibleSection
          title='find-me-also-in'
          className={styles.collapsible}
        >
          <ContactsLeftPanel mode='secondary' />
        </MobileCollapsibleSection>
        <div className={styles.columnContent}>
          <ContactForm
            nameInputState={nameInputState}
            emailInputState={emailInputState}
            messageInputState={messageInputState}
            className={styles.form}
          />
        </div>
      </div>
      <div className={styles.column}>
        <div className={styles.columnContent}>
          <ContactFormCode
            name={nameInputState[0].value}
            email={emailInputState[0].value}
            message={messageInputState[0].value}
            className={styles.code}
          />
        </div>
      </div>
    </ContentBody>
  );
};
