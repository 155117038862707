export enum AlertTypes {
  DEFAULT,
  ERROR,
}

export interface IAlertMessage {
  message: string;
  delay: number;
  id: number;
  type: AlertTypes;
}

export const ejectErrorMessage = (message: any): string => {
  if (typeof message === 'string') return message;
  if (message?.response?.data?.message) return message.response.data.message;
  if (message?.message) return message.message;
  return 'Something went wrong. Please try again';
};

type Config = {
  enabled: boolean;
  frozen: boolean;
  messages: IAlertMessage[];
};

export const alertDebug: Config = {
  enabled: false,
  frozen: false,
  messages: [
    {
      message:
        "I'm a debug message. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto distinctio obcaecati temporibus tenetur voluptates? Aliquam dicta incidunt repellendus. Deleniti dolorem eius error, facere molestias qui quis reprehenderit saepe, sit ullam ut, voluptas! Iure quae temporibus voluptatibus. Blanditiis eveniet excepturi facere, harum, ipsam magnam minus nam natus odit quam quia velit!",
      type: AlertTypes.DEFAULT,
      delay: 3000,
      id: 1,
    },
    {
      message:
        "I'm an error debug message. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto distinctio obcaecati temporibus tenetur voluptates? Aliquam dicta incidunt repellendus. Deleniti dolorem eius error, facere molestias qui quis reprehenderit saepe, sit ullam ut, voluptas! Iure quae temporibus voluptatibus. Blanditiis eveniet excepturi facere, harum, ipsam magnam minus nam natus odit quam quia velit!",
      type: AlertTypes.ERROR,
      delay: 5000,
      id: 2,
    },
    {
      message: "I'm a debug message",
      type: AlertTypes.DEFAULT,
      delay: 4000,
      id: 3,
    },
  ],
};
