import { SetState } from '../../../utils/ISetState';
import { useState } from 'react';

export interface InputState {
  value: string;
  hint?: string | string[];
  valid?: boolean;
}

export const defaultInputState: InputState = {
  value: '',
  valid: true,
  hint: '',
};

export type IUseInputState = [
  InputState,
  SetState<InputState>,
  (newValue: string) => void,
];

export const useInputState = (
  initialValue: InputState = defaultInputState,
): IUseInputState => {
  const [state, setState] = useState<InputState>(initialValue);

  const handleChange = (newValue: string) => {
    setState({ valid: true, hint: '', value: newValue });
  };

  return [state, setState, handleChange];
};
