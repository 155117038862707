import React from 'react';
import { AppHeader } from './components/layout/app/header';
import { AppFooter } from './components/layout/app/footer';
import { AppBody } from './components/layout/app/body';
import { IOSPeek } from './components/layout/IOSPeek';
import { Route, Routes } from 'react-router-dom';
import { NotFoundPage } from './components/pages/notFound';
import { MainPage } from './components/pages/main';
import { AboutPage } from './components/pages/about';
import { ProjectsPage } from './components/pages/projects';
import { ContactsPage } from './components/pages/contacts';

type NavElementData = {
  path: string;
  navUrl?: string;
  title: string;
  Component: React.FunctionComponent;
};

// TODO:: transition between pages

export const appNavigation: NavElementData[] = [
  { path: '/', title: '_hello', Component: MainPage },
  { path: '/about', title: '_about-me', Component: AboutPage },
  {
    path: 'projects/:projectId?',
    navUrl: '/projects',
    title: '_projects',
    Component: ProjectsPage,
  },
  { path: '/contacts', title: '_contact-me', Component: ContactsPage },
];

export const App = () => (
  <>
    <AppHeader />
    <AppBody>
      <Routes>
        {appNavigation.map((el) => (
          <Route path={el.path} element={<el.Component />} key={el.path} />
        ))}
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
    </AppBody>
    <AppFooter />
    <IOSPeek />
  </>
);
