// keep synchronised with variables in root index.scss
export const appColors = {
  // main
  main: '#011627',
  mainDarker: '#011221',
  mainDarkest: '#01080E',
  // text
  text: '#607B96',
  textActive: '#FFF',
  // input specific
  inputText: '#465E77',
  inputShadow: '#607B964D',
  // accent
  accent: '#FEA55F',
  accentBlue: '#4D6BCE',
  // other
  justBlue: '#3A49A4',
  justGreen: '#43D9AD',
  justSalad: '#3C9D93',
  justPurple: '#C98BDF',
  justBrick: '#E99287',
  border: '#1E2D3D',
};

const getDecimalColor = (color: string) => {
  const R = color.substring(1, 3);
  const G = color.substring(3, 5);
  const B = color.substring(5, 7);
  const decR = parseInt(R, 16);
  const decG = parseInt(G, 16);
  const decB = parseInt(B, 16);
  return { R: decR, G: decG, B: decB };
};

export const addOpacityToHex = (color: string, opacity: number) => {
  const dec = getDecimalColor(color);
  return `rgba(${dec.R}, ${dec.G}, ${dec.B}, ${opacity})`;
};
