import React from 'react';
import styles from './style.scss';
import clsx from 'clsx';
import { addOpacityToHex, appColors } from '../../../utils/constants';

interface Props {
  checked: boolean;
  onChange?: () => void;
  className?: string;
}

export const Checkbox = ({ checked, onChange, className }: Props) => {
  return (
    <input
      type='checkbox'
      checked={checked}
      onChange={onChange}
      className={clsx(styles.checkbox, className)}
      readOnly={!onChange}
      style={
        {
          '--checkbox-outline-color': addOpacityToHex(appColors.text, 0.5),
        } as React.CSSProperties
      }
    />
  );
};
