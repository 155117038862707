import React, { useMemo } from 'react';
import styles from './style.scss';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { Loader } from '../../loader';
import { capitalize } from '../../../utils/stuff';
import { appColors } from '../../../utils/constants';

export type ButtonColor = 'primary' | 'secondary' | 'white';

interface Props {
  children: React.ReactNode | string;
  color?: ButtonColor;
  link?: string;
  targetBlank?: boolean;
  variant?: 'contained' | 'outlined';
  className?: string;
  onClick?: () => any;
  loading?: boolean;
  disabled?: boolean;
}

export const Button = ({
  children,
  className,
  variant = 'contained',
  color = 'primary',
  onClick,
  loading,
  disabled,
  link,
  targetBlank,
}: Props) => {
  const finalClassName = clsx(
    styles.button,
    styles[`button${capitalize(variant)}`],
    styles[`buttonColor${capitalize(color)}`],
    className,
    loading && styles.buttonLoading,
    disabled && styles.buttonDisabled,
  );

  const loaderColor = useMemo(() => {
    if (variant === 'contained') {
      if (color === 'secondary') return '#FFF';
      return '#000';
    }
    if (color === 'primary') return appColors.accent;
    if (color === 'secondary') return appColors.main;
    return appColors.textActive;
  }, [color, variant]);

  const buttonBody = (
    <>
      <div className={styles.buttonBody}>
        {typeof children === 'string' ? <span>{children}</span> : children}
      </div>
      <div className={styles.buttonLoaderWrapper}>
        <Loader size={20} color={loaderColor} />
      </div>
    </>
  );

  if (link) {
    return (
      <Link
        to={link}
        className={finalClassName}
        target={
          targetBlank || link.startsWith('http') || link.startsWith('//')
            ? '_blank'
            : undefined
        }
      >
        {buttonBody}
      </Link>
    );
  }

  return (
    <button
      className={finalClassName}
      onClick={!loading && !disabled ? onClick : undefined}
    >
      {buttonBody}
    </button>
  );
};
