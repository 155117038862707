import React, { useRef, useState } from 'react';
import styles from './style.scss';
import { projects } from '../../projects/utils';
import { BlurBackground } from '../../../svg/BlurBackground';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as ISwiper } from 'swiper/types';
import { ProjectsSliderCard } from './projectCard';
import { Autoplay, Mousewheel } from 'swiper';
import clsx from 'clsx';

const config = {
  height: 214,
  distance: 20,
  perView: 5,
};

type Opacities = {
  [key: number]: number;
};

type State = {
  opacities: Opacities;
  activeIndex: number;
};

const getOpacities = (main: number): Opacities => {
  const getIndex = (shift: number) => {
    let result = main + shift;
    if (result < 0) {
      result = projects.length + result;
    }
    if (result > projects.length - 1) {
      result = result - projects.length;
    }
    return result;
  };
  return {
    [getIndex(-2)]: 0.1,
    [getIndex(-1)]: 0.4,
    [main]: 1,
    [getIndex(1)]: 0.4,
    [getIndex(2)]: 0.1,
  };
};

// for touch handling - touchstart, touchmove, touchend, touchcancel
// for mouse - mousedown, mousemove, mouseup, mouseleave(?)

// TODO:: Free mode, preroll on page mount
// TODO:: fix wrong slide selected on click bug
// TODO:: inject project dialog on this page too instead of redirecting

interface Props {
  className?: string;
}

export const ProjectsSlider = ({ className }: Props) => {
  const initialized = useRef<boolean>(false);
  const swiperRef = useRef<ISwiper | undefined>(undefined);
  const [state, setState] = useState<State>({
    opacities: getOpacities(0),
    activeIndex: 0,
  });

  const initialize = (swiper: ISwiper) => {
    initialized.current = true;
    swiperRef.current = swiper;
  };

  const onSlideChange = (swiper: ISwiper) => {
    if (initialized.current) {
      swiperRef.current = swiper;
      setState({
        opacities: getOpacities(swiper.realIndex),
        activeIndex: swiper.realIndex,
      });
    }
  };

  const swiperHeight = (config.height + config.distance) * config.perView;

  return (
    <div className={clsx(styles.projectsSlider, className)}>
      <Swiper
        modules={[Autoplay, Mousewheel]}
        autoplay={false}
        mousewheel={true}
        spaceBetween={20}
        direction='vertical'
        className={styles.swiper}
        slidesPerView={5}
        height={swiperHeight}
        style={{ maxHeight: swiperHeight }}
        centeredSlides
        loop
        onSlideChange={(swiper) => onSlideChange(swiper)}
        slideToClickedSlide={true}
        onSwiper={(swiper) => initialize(swiper)}
      >
        {projects.map((project, i) => (
          <SwiperSlide key={project.name}>
            <ProjectsSliderCard
              data={project}
              opacity={state.opacities[i] || 0.1}
              cardHeight={config.height}
              active={i === state.activeIndex}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <BlurBackground
        colorTop={projects[state.activeIndex].colors[0]}
        colorBottom={projects[state.activeIndex].colors[1]}
        className={styles.blur}
        blur={70}
      />
    </div>
  );
};
