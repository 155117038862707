import React from 'react';
import styles from './style.scss';
import { Checkbox } from '../../../controls/checkbox';
import { projectsTagsAsArray } from '../utils';
import clsx from 'clsx';

interface Props {
  appliedIds: string[];
  handleFilterClick: (id: string) => void;
}

export const ProjectsFiltersPanel = ({
  appliedIds,
  handleFilterClick,
}: Props) => {
  return (
    <ul className={styles.filtersPanel}>
      {projectsTagsAsArray.map((filter) => {
        const isActive = appliedIds.includes(filter.id);
        return (
          <li
            key={filter.id}
            className={clsx(styles.filter, isActive && styles.active)}
            onClick={() => handleFilterClick(filter.id)}
          >
            <Checkbox checked={isActive} className={styles.filterCheckbox} />
            <filter.icon className={styles.filterIcon} />
            {filter.title}
          </li>
        );
      })}
    </ul>
  );
};
