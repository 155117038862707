import React from 'react';
import styles from './style.scss';

interface Props {
  children: React.ReactNode;
}

export const AppBody = ({ children }: Props) => {
  return <main className={styles.appBody}>{children}</main>;
};
