import React from 'react';
import styles from './style.scss';
import { Button } from '../../controls/button';
import { ProjectsSlider } from './projectsSlider';
import { BlurBackground } from '../../svg/BlurBackground';
import { appColors } from '../../../utils/constants';
import clsx from 'clsx';

// TODO:: fix glitch on image size when refreshing page (main and projects pages)
// TODO:: external-link icons for project view
// TODO:: figure out name to replace Greg. The Greg

export const MainPage = () => (
  <div className={styles.mainPage}>
    <div className={styles.mainPageContent}>
      <div className={styles.left}>
        <div className={styles.leftTop}>
          <h5 className={styles.aboveName}>Hi all, I'm</h5>
          <h1 className={styles.name}>Greg. The&nbsp;Greg</h1>
          <h2 className={styles.belowName}>Fullstack Developer</h2>
        </div>
        <div className={styles.leftBottom}>
          <p className={clsx(styles.comment, styles.commentPc)}>
            // This site serves as a web portfolio
          </p>
          <p className={clsx(styles.comment, styles.commentXs)}>
            // This site is a web portfolio
          </p>
          <p className={styles.comment}>// Feel free to hang around </p>
          <Button color='primary' link={'/assets/downloads/cv.pdf'} targetBlank>
            Download CV
          </Button>
        </div>
      </div>
      <ProjectsSlider className={styles.right} />
      <div className={styles.blurWrapper}>
        <BlurBackground
          colorTop={appColors.justGreen}
          colorBottom={appColors.accentBlue}
          blur={90}
          className={styles.blur}
        />
      </div>
    </div>
  </div>
);
