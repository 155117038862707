import React, { useEffect, useMemo, useState } from 'react';
import styles from './style.scss';
import { ContentWrapper } from '../../layout/content/wrapper';
import { ContentLeftPanel } from '../../layout/content/leftPanel';
import { ContentHeader } from '../../layout/content/header';
import { ContentBody } from '../../layout/content/body';
import { ContentHeaderTab } from '../../layout/content/headerTab';
import { ContentLeftPanelSection } from '../../layout/content/leftPanel/section';
import { AboutFileStructure } from './fileStructure';
import { aboutData, FileLine } from './utils';
import { CommentTextBlock } from './commentTextBlock';
import { Button } from '../../controls/button';
import useWindowSize from '../../../hooks/useWindowSize';
import { MobileCollapsibleSection } from '../../layout/content/mobileCollapsibleSection';

// TODO:: tldr

export const AboutPage = () => {
  const [sizeX] = useWindowSize();
  const [selectedFile, setSelectedFile] = useState<FileLine | null>(null);
  const [mobileCollapsibleOpened, setMobileCollapsibleOpened] = useState(false);

  const readme = useMemo(() => {
    return aboutData.find((el) => el.title === 'README.md');
  }, []);

  useEffect(() => {
    if (readme && readme.type === 'file') {
      setSelectedFile(readme);
    }
  }, [readme]);

  const handleMobileStructureClick = (file: FileLine) => {
    setSelectedFile(file);
    setMobileCollapsibleOpened(false);
  };

  return (
    <ContentWrapper>
      <ContentLeftPanel className={styles.aboutLeftPanel}>
        <ContentLeftPanelSection title={'personal-info'} fullHeight>
          <AboutFileStructure
            lines={aboutData}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
          />
        </ContentLeftPanelSection>
      </ContentLeftPanel>
      <ContentBody className={styles.aboutContent}>
        <div className={styles.contentPart}>
          <ContentHeader>
            <ContentHeaderTab
              onClose={
                readme?.type === 'file'
                  ? () => setSelectedFile(readme)
                  : undefined
              }
            >
              {selectedFile?.title || '_void'}
            </ContentHeaderTab>
          </ContentHeader>
          <MobileCollapsibleSection
            title={'personal-info'}
            opened={mobileCollapsibleOpened}
            setOpened={setMobileCollapsibleOpened}
            contentClassName={styles.collapsibleContent}
            className={styles.collapsible}
          >
            <AboutFileStructure
              lines={aboutData}
              selectedFile={selectedFile}
              setSelectedFile={handleMobileStructureClick}
            />
          </MobileCollapsibleSection>
          <CommentTextBlock
            text={
              selectedFile
                ? selectedFile.content
                : 'Nothing opened. Start by opening a file on the left panel'
            }
            className={styles.commentTextWrapper}
            disableDecorations={sizeX <= 800}
          />
        </div>
        <div className={styles.contentPart}>
          <ContentHeader>
            <ContentHeaderTab>tldr;</ContentHeaderTab>
          </ContentHeader>
          <div className={styles.tldr}>
            <Button
              link={'/assets/downloads/cv.pdf'}
              targetBlank
              variant='outlined'
              color='white'
            >
              Download CV
            </Button>
          </div>
        </div>
      </ContentBody>
    </ContentWrapper>
  );
};
