import React from 'react';
import styles from './style.scss';
import { appColors } from '../../../utils/constants';

interface RadialProgressProps {
  done: number;
  total: number;
  size?: number;
  color?: string;
  className?: string;
}

export const RadialProgress = ({
  done,
  total,
  size = 26,
  color = appColors.text,
  className,
}: RadialProgressProps) => {
  const percent = done / total;
  const radius = size / 2 - 4;
  const length = Math.PI * radius * 2;

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      className={className}
    >
      <circle
        cx={size / 2}
        cy={size / 2}
        r={radius}
        fill='none'
        stroke={color}
        strokeWidth={Math.round(size / 10)}
        className={styles.radialProgress}
        strokeDasharray={`${length * percent} ${length - length * percent}`}
      />
    </svg>
  );
};
