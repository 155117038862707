import React, { useEffect, useMemo, useRef, useState } from 'react';
import styles from './style.scss';
import { alertDebug, AlertTypes, IAlertMessage } from '../utils';
import { RadialProgress } from '../radialProgress';
import { appColors } from '../../../utils/constants';
import clsx from 'clsx';

interface AlertMessageProps {
  message: IAlertMessage;
  removeSelf: () => void;
}

export const AlertMessage = ({ message, removeSelf }: AlertMessageProps) => {
  const [progress, setProgress] = useState(0);
  const ref = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(-1);
  const [vanishing, setVanishing] = useState(false);

  const { time, almost } = useMemo(() => {
    const time = Math.max(message.delay, 500);
    const almost = time - 150;
    return { time, almost };
  }, [message.delay]);

  // this useEffect is responsible for disappearing and self-removal of message
  useEffect(() => {
    setHeight(ref.current ? ref.current.scrollHeight : 1000);

    // setting timer to __hide__ a message after time
    // actual removal in useEffect below
    const terminationTimer = setTimeout(() => {
      if (!alertDebug.frozen) {
        setVanishing(true);
      }
    }, time);

    return () => clearTimeout(terminationTimer);
  }, []);

  // this useEffect tracks when message starts to disappear and removes it after
  useEffect(() => {
    if (vanishing) {
      const removalTimer = setTimeout(() => {
        removeSelf();
      }, 250);
      return () => clearTimeout(removalTimer);
    }
  }, [vanishing]);

  // this useEffect is responsible for progress circle
  useEffect(() => {
    if (alertDebug.frozen) {
      setProgress(Math.round(time * 0.7));
      return;
    }

    const progressStep = 50;
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev + progressStep > almost) {
          clearInterval(interval);
          return almost;
        }
        return prev + progressStep;
      });
    }, progressStep);

    return () => clearInterval(interval);
  }, []);

  const specificClassName = useMemo<string>(() => {
    switch (message.type) {
      case AlertTypes.ERROR:
        return styles.error;
      default:
        return styles.regular;
    }
  }, [message.type]);

  return (
    <div
      className={clsx(
        styles.alertMessageWrapper,
        vanishing && styles.vanishing,
      )}
      style={!vanishing && height > 0 ? { maxHeight: height } : undefined}
    >
      <div
        className={clsx(styles.message, specificClassName)}
        onClick={removeSelf}
        ref={ref}
      >
        {message.message}
        <RadialProgress
          done={progress}
          total={almost}
          color={appColors.text}
          size={24}
          className={styles.progress}
        />
      </div>
    </div>
  );
};
