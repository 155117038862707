import React from 'react';
import styles from './style.scss';
import clsx from 'clsx';

interface ContentWrapperProps {
  children: React.ReactNode;
  className?: string;
}

export const ContentWrapper = ({
  children,
  className,
}: ContentWrapperProps) => {
  return (
    <div className={clsx(styles.contentWrapper, className)}>{children}</div>
  );
};
