export const capitalize = (input: string): string => {
  if (input.length <= 0) return input;
  return input[0].toUpperCase() + input.substring(1);
};

export const getRandomArrayElement = <T>(input: T[]): T => {
  return input[Math.trunc(Math.random() * input.length)];
};

export const shuffleArray = <T>(input: T[]): T[] => {
  return input.sort(() => Math.random() - 0.5);
};

type Grow<T, A extends Array<T>> = ((x: T, ...xs: A) => void) extends (
  ...a: infer X
) => void
  ? X
  : never;
type GrowToSize<T, A extends Array<T>, N extends number> = {
  0: A;
  1: GrowToSize<T, Grow<T, A>, N>;
}[A['length'] extends N ? 0 : 1];

export type ArrayOfSize<T, N extends number> = GrowToSize<T, [], N>;
