import { useState } from 'react';
import { SetState } from '../utils/ISetState';

//utils

const arraify = <T>(input: T | T[]): T[] => {
  if (!Array.isArray(input)) return [input];
  return input;
};

type Predicate<T> = (arg: T) => boolean;
type Setter<T> = (arg: T) => T;

export interface IArrayState<T> {
  push: (element: T) => void;
  pushToBegin: (element: T) => void;
  pushUniquePrimitive: (element: T) => void;
  remove: (predicate: Predicate<T>) => void;
  removeOne: (predicate: Predicate<T>) => void;
  removeByIndex: (index: number) => void;
  replace: (predicate: Predicate<T>, setter: Setter<T>) => void;
  replaceOne: (predicate: Predicate<T>, setter: Setter<T>) => void;
  replaceByIndex: (index: number, setter: Setter<T>) => void;
  value: T[];
  setValue: SetState<T[]>;
}

class ArrayState<T> implements IArrayState<T> {
  private readonly _value: T[];
  private readonly _setValue: SetState<T[]>;

  constructor(initialState: T[]) {
    const [state, setState] = useState(arraify(initialState));
    this._value = state;
    this._setValue = setState;
  }

  public push = (value: T) => {
    this._setValue((prev) => prev.concat(value));
  };

  public pushToBegin = (value: T) => {
    this._setValue((prev) => [value].concat(prev));
  };

  public pushUniquePrimitive = (value: T) => {
    if (this._value.includes(value)) return;
    this.push(value);
  };

  public remove = (predicate: Predicate<T>) => {
    this._setValue((prev) => prev.filter((el) => !predicate(el)));
  };

  public removeOne = (predicate: Predicate<T>) => {
    this._setValue((prev) => {
      const index = prev.findIndex((el) => predicate(el));
      return prev.filter((el, i) => i !== index);
    });
  };

  public removeByIndex = (index: number) => {
    this._setValue((prev) => prev.filter((_, i) => index !== i));
  };

  public replace = (predicate: Predicate<T>, setter: Setter<T>) => {
    this._setValue((prev) =>
      prev.map((el) => {
        if (predicate(el)) return setter(el);
        return el;
      }),
    );
  };

  public replaceOne = (predicate: Predicate<T>, setter: Setter<T>) => {
    this._setValue((prev) => {
      const index = prev.findIndex(predicate);
      return prev.map((el, i) => {
        if (i === index) return setter(el);
        return el;
      });
    });
  };

  public replaceByIndex = (index: number, setter: Setter<T>) => {
    this._setValue((prev) =>
      prev.map((el, i) => {
        if (i === index) return setter(el);
        return el;
      }),
    );
  };

  get value(): T[] {
    return this._value;
  }

  get setValue(): SetState<T[]> {
    return this._setValue;
  }
}

const useArrayState = <T>(initialState: T[]): IArrayState<T> =>
  new ArrayState<T>(initialState);

export default useArrayState;
