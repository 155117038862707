import React, { useRef, useState } from 'react';
import styles from './style.scss';
import { Input } from '../../../../controls/input';
import {
  defaultInputState,
  IUseInputState,
} from '../../../../controls/input/utils';
import {
  isEmailValid,
  isNotEmptyString,
} from '../../../../../utils/validators';
import clsx from 'clsx';
import { Button } from '../../../../controls/button';
import { fetcher } from '../../../../../utils/fetcher';
import { useAlert } from '../../../../../contexts/alert';

interface ContactFormProps {
  nameInputState: IUseInputState;
  emailInputState: IUseInputState;
  messageInputState: IUseInputState;
  className?: string;
}

export const ContactForm = ({
  nameInputState,
  emailInputState,
  messageInputState,
  className,
}: ContactFormProps) => {
  const alert = useAlert();
  const [name, setName, handleNameChange] = nameInputState;
  const [email, setEmail, handleEmailChange] = emailInputState;
  const [message, setMessage, handleMessageChange] = messageInputState;
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const mailRef = useRef<HTMLInputElement>(null);
  const msgRef = useRef<HTMLInputElement>(null);

  const focusSmth = (ref: typeof mailRef) => {
    const el = ref.current;
    if (el) el.focus();
  };

  const focusMail = () => focusSmth(mailRef);
  const focusMessage = () => focusSmth(msgRef);

  const validateInputs = (): boolean => {
    let result = true;

    // name
    if (!isNotEmptyString(name.value)) {
      result = false;
      setName((prev) => ({
        ...prev,
        valid: false,
        hint: 'Please enter your name',
      }));
    }

    // email
    const emailValidation = isEmailValid(email.value);
    if (!emailValidation.result) {
      result = false;
      setEmail((prev) => ({
        ...prev,
        valid: false,
        hint: emailValidation.reason,
      }));
    }

    // enquiry
    if (!isNotEmptyString(message.value)) {
      result = false;
      setMessage((prev) => ({
        ...prev,
        valid: false,
        hint: 'Please enter the message',
      }));
    }
    return result;
  };

  const handleSubmit = () => {
    if (!validateInputs()) return;
    setSubmitLoading(true);
    fetcher.contact
      .sendEnquiry({
        name: name.value,
        email: email.value,
        message: message.value,
      })
      .then(() => {
        alert.show('Sent');
        setSubmitted(true);
      })
      .catch((err) => alert.showError(err))
      .finally(() => setSubmitLoading(false));
  };

  const reset = () => {
    setSubmitted(false);
    setSubmitLoading(false);
    setName(defaultInputState);
    setEmail(defaultInputState);
    setMessage(defaultInputState);
  };

  if (submitted) {
    return (
      <div className={styles.postSubmit}>
        <h2 className={styles.title}>Thank you! 🤘</h2>
        <p className={styles.msg}>
          Your message has been accepted. I'll review as soon as possible.
        </p>
        <Button color='secondary' onClick={reset}>
          send-new-message
        </Button>
      </div>
    );
  }

  return (
    <div className={clsx(styles.contactForm, className)}>
      <div className={styles.inputs}>
        <Input
          label='_name'
          state={name}
          onChange={handleNameChange}
          onEnterPress={focusMail}
          disabled={submitLoading}
        />
        <Input
          label='_email'
          state={email}
          onChange={handleEmailChange}
          onEnterPress={focusMessage}
          inputRef={mailRef}
          disabled={submitLoading}
        />
        <Input
          label='_message'
          state={message}
          onChange={handleMessageChange}
          onEnterPress={handleSubmit}
          inputRef={msgRef}
          disabled={submitLoading}
          multi
        />
      </div>
      <Button
        color={'secondary'}
        loading={submitLoading}
        onClick={handleSubmit}
      >
        submit-message
      </Button>
    </div>
  );
};
