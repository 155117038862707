import React, { useEffect, useMemo, useState } from 'react';
import styles from './style.scss';
import { ContentHeader } from '../../layout/content/header';
import { ContentLeftPanel } from '../../layout/content/leftPanel';
import { ContentBody } from '../../layout/content/body';
import { ContentWrapper } from '../../layout/content/wrapper';
import { ContentHeaderTab } from '../../layout/content/headerTab';
import { ProjectsFiltersPanel } from './filtersPanel';
import { Projects } from './projects';
import { ContentLeftPanelSection } from '../../layout/content/leftPanel/section';
import { projectsTagsAsArray } from './utils';
import useWindowSize from '../../../hooks/useWindowSize';
import { useAlert } from '../../../contexts/alert';

// TODO:: responsiveness for alert

export const ProjectsPage = () => {
  const alert = useAlert();
  const [sizeX] = useWindowSize();
  const [appliedIds, setAppliedIds] = useState<string[]>([]);

  useEffect(() => {
    if (sizeX <= 800) {
      clearFilters();
    }
  }, [sizeX <= 800]);

  const handleFilterChange = (filterId: string) => {
    setAppliedIds((prev) => {
      if (prev.includes(filterId)) {
        return prev.filter((el) => el !== filterId);
      }
      return prev.concat(filterId);
    });
  };

  const clearFilters = () => setAppliedIds([]);

  const handleCloseClick = () => {
    if (appliedIds.length === 0) {
      alert.show('No filters selected, nothing to reset');
    } else {
      clearFilters();
    }
  };

  const tabTitle = useMemo<string>(() => {
    const titles = projectsTagsAsArray
      .filter((t) => appliedIds.includes(t.id))
      .map((t) => t.title);
    if (titles.length === 0) return 'all-projects';
    return titles.join('; ');
  }, [appliedIds]);

  return (
    <ContentWrapper>
      <ContentLeftPanel className={styles.projectsLeftPanel}>
        <ContentLeftPanelSection title={'projects'} fullHeight>
          <ProjectsFiltersPanel
            appliedIds={appliedIds}
            handleFilterClick={handleFilterChange}
          />
        </ContentLeftPanelSection>
      </ContentLeftPanel>
      <ContentBody className={styles.projectsContent}>
        <ContentHeader mobileBreakpoint={700}>
          <ContentHeaderTab onClose={handleCloseClick} mobileBreakpoint={700}>
            {tabTitle}
          </ContentHeaderTab>
        </ContentHeader>
        <Projects appliedIds={appliedIds} resetFilters={clearFilters} />
      </ContentBody>
    </ContentWrapper>
  );
};
