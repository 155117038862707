import React, { useEffect, useMemo, useState } from 'react';
import styles from './style.scss';
import { Project, projects, projectsTagsAsArray } from '../utils';
import { Button } from '../../../controls/button';
import { SvgProps } from '../../../svg/SvgProps';
import { Dialog } from '../../../dialog';
import { ProjectDialogBody } from './projectDialogBody';
import { useNavigate, useParams } from 'react-router-dom';

interface ProjectsProps {
  appliedIds: string[];
  resetFilters: () => void;
}

export const Projects = ({ appliedIds, resetFilters }: ProjectsProps) => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const [selected, setSelected] = useState<Project | null>(null);
  const [dialogOpened, setDialogOpened] = useState(false);

  useEffect(() => {
    if (!projectId) {
      setDialogOpened(false);
    } else {
      const proj = projects.find((p) => p.url === projectId);
      if (proj) {
        setSelected(proj);
        setDialogOpened(true);
      } else {
        navigate('/projects');
      }
    }
  }, [projectId]);

  const filteredProjects = useMemo(() => {
    if (appliedIds.length === 0) return projects;
    return projects.filter((p) =>
      p.tagsIds.some((tId) => appliedIds.includes(tId)),
    );
  }, [appliedIds]);

  const openProject = (project: Project) => {
    navigate(`/projects/${project.url}`);
  };

  const closeProject = () => {
    navigate('/projects');
  };

  return (
    <>
      <div className={styles.projectsWrapper}>
        {filteredProjects.length > 0 ? (
          <ul className={styles.projects}>
            {filteredProjects.map((p, i) => (
              <ProjectCard data={p} onOpen={() => openProject(p)} key={i} />
            ))}
          </ul>
        ) : (
          <div className={styles.noProjects}>
            <p className={styles.text}>No projects match selected filters</p>
            <Button onClick={resetFilters} color='secondary'>
              clear-filters
            </Button>
          </div>
        )}
      </div>
      <Dialog
        opened={dialogOpened}
        onClose={closeProject}
        className={styles.projectDialog}
      >
        {selected && <ProjectDialogBody project={selected} />}
      </Dialog>
    </>
  );
};

interface ProjectCardProps {
  data: Project;
  onOpen: () => void;
}

const ProjectCard = ({ data, onOpen }: ProjectCardProps) => {
  // taking first 5 (or less) tags to display on project card
  const icons = useMemo<React.FunctionComponent<SvgProps>[]>(() => {
    return projectsTagsAsArray
      .filter((t) => data.tagsIds.includes(t.id))
      .map((t) => t.icon)
      .slice(0, 5);
  }, [data.tagsIds]);

  return (
    <li className={styles.project}>
      <div className={styles.projectHeader}>
        Project {data.number}{' '}
        <span className={styles.comment}>// {data.name}</span>
      </div>
      <div className={styles.card}>
        <div className={styles.cardImageWrapper}>
          <img
            src={data.image}
            alt={`Project ${data.name} image`}
            className={styles.cardImage}
          />
          <div className={styles.cardTags}>
            {icons.map((Icon, i) => (
              <Icon className={styles.icon} key={i} />
            ))}
          </div>
        </div>
        <div className={styles.cardText}>
          <p className={styles.cardDescription}>{data.disclaimer}</p>
          <Button color='secondary' onClick={onOpen}>
            view-project
          </Button>
        </div>
      </div>
    </li>
  );
};
