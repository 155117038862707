import React, { useState } from 'react';
import styles from './style.scss';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { appNavigation } from '../../../../App';
import { MenuButton } from './menuButton';

// TODO:: fancy animation of active link change
// slight transition of underline to the next/previous element

export const AppHeader = () => {
  const [menuOpened, setMenuOpened] = useState(false);

  const closeMenu = () => {
    setMenuOpened(false);
  };

  const stopMouseEvent = (e: React.MouseEvent | React.TouchEvent) => {
    e.stopPropagation();
  };

  return (
    <header className={styles.appHeader}>
      <NavLink to={'/'} className={styles.logo}>
        The Greg
      </NavLink>
      <nav className={styles.pages}>
        {appNavigation.map((el) => (
          <NavLink
            className={(state) => {
              return clsx(styles.link, state.isActive && styles.linkActive);
            }}
            to={el.navUrl || el.path}
            key={el.path}
          >
            {el.title}
          </NavLink>
        ))}
      </nav>
      <MenuButton
        opened={menuOpened}
        onClick={(e) => {
          stopMouseEvent(e);
          setMenuOpened((prev) => !prev);
        }}
        className={styles.menuButton}
      />
      <div
        className={clsx(styles.mobileMenu, menuOpened && styles.visible)}
        onClick={stopMouseEvent}
        onTouchMove={stopMouseEvent}
      >
        <nav className={styles.menuNavigation}>
          {appNavigation.map((el) => (
            <NavLink
              className={styles.menuLink}
              to={el.navUrl || el.path}
              key={el.path}
              onClick={closeMenu}
            >
              {el.title}
            </NavLink>
          ))}
        </nav>
      </div>
      {menuOpened && (
        <div
          className={styles.tabletMenuOverlay}
          onClick={closeMenu}
          onTouchMove={closeMenu}
        />
      )}
    </header>
  );
};
