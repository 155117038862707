import React, { useMemo, useRef } from 'react';
import styles from './style.scss';
import clsx from 'clsx';
import { CloseIcon } from '../../../svg/icons/Close';
import { useAlert } from '../../../../contexts/alert';
import { getRandomArrayElement, shuffleArray } from '../../../../utils/stuff';
import useWindowSize from '../../../../hooks/useWindowSize';

const firstResponses = [
  'This close icon is just a decorative element. Feel free to press it, but nothing will be closed',
  "Pressing this button doesn't really make sense. It's a simple design solution, not a functional element",
  "Clicking on this element serves no functional purpose. It's purely a design choice, bruh.",
];

const secondResponses = [
  "Still doesn't work",
  'Hello. Am I a joke to you?',
  'Whatever',
  'Oh yes. Click me again please. And hundred times more',
  "Unbelievable! Doesn't work! But you can definitely try one more time",
  'I wonder if you ever gonna stop',
  "I'm serious, there's no hidden feature. It just doesn't work",
  "It. Doesn't. Work",
  "Hello! I'm a button! Can you please stop pressing me?",
  'Click it again if you believe in miracles',
  'You just clicked a perfectly useless button',
  "You're persistent, I'll give you that",
  "I'm a button, not a magician",
  'This button is not a secret passage to Narnia',
  'Nope, still no hidden treasure behind this button',
  'One day, someone might mistake you for a hacker',
  "I'm starting to think you like this button more than others",
  "I'm flattered by your relentless clicks",
  'Click me one more time, and a unicorn might appear... Or maybe not. Probably not',
  "So, for a case you didn't get it - this icon doesn't work",
];

interface ContentHeaderTitleProps {
  children: React.ReactNode;
  mobileBreakpoint?: number;
  onClose?: () => void;
  className?: string;
}

export const ContentHeaderTab = ({
  children,
  className,
  mobileBreakpoint = 600,
  onClose,
}: ContentHeaderTitleProps) => {
  const [sizeX] = useWindowSize();
  const alert = useAlert();
  const counter = useRef(0);

  const { first, second } = useMemo(() => {
    return {
      first: shuffleArray(firstResponses),
      second: shuffleArray(secondResponses),
    };
  }, []);

  const handleCloseClick = () => {
    if (onClose) {
      return onClose();
    }
    if (counter.current < first.length) {
      alert.show(first[counter.current], 5000);
    } else if (counter.current < second.length + first.length) {
      alert.show(second[counter.current - first.length], 5000);
    } else {
      alert.show(getRandomArrayElement(second), 5000);
    }
    counter.current++;
  };

  return (
    <div
      className={clsx(
        styles.contentHeaderTitle,
        sizeX <= mobileBreakpoint && styles.mobile,
        className,
      )}
    >
      <div className={styles.text}>{children}</div>
      <CloseIcon className={styles.icon} onClick={handleCloseClick} />
    </div>
  );
};
