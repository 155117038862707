import React from 'react';
import styles from './style.scss';
import clsx from 'clsx';
import { ContentHeader } from '../../header';
import { ArrowFilledIcon } from '../../../../svg/icons/ArrowFilled';

interface ContentLeftPanelHeaderProps {
  children: React.ReactNode;
  title: string;
  fullHeight?: boolean;
  contentClassName?: string;
  headerClassName?: string;
}

export const ContentLeftPanelSection = ({
  children,
  title,
  fullHeight,
  contentClassName,
  headerClassName,
}: ContentLeftPanelHeaderProps) => {
  return (
    <>
      <ContentHeader
        className={clsx(styles.leftPanelSectionHeader, headerClassName)}
      >
        <ArrowFilledIcon className={styles.arrow} />
        <div className={styles.text}>{title}</div>
      </ContentHeader>
      <div
        className={clsx(
          styles.leftPanelSectionContent,
          fullHeight && styles.fullHeight,
          contentClassName,
        )}
      >
        {children}
      </div>
    </>
  );
};
