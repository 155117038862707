import React, { useMemo } from 'react';
import styles from './style.scss';
import clsx from 'clsx';

interface Props {
  name: string;
  email: string;
  message: string;
  className?: string;
}

// TODO:: proper parser

function formatDate(inputDate: Date): string {
  // Define days and months arrays
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  // Extract day, date, and month from the inputDate
  const day = days[inputDate.getDay()];
  const date = inputDate.getDate();
  const month = months[inputDate.getMonth()];

  // Format the string
  return `${day} ${date} ${month}`;
}

export const ContactFormCode = ({ name, email, message, className }: Props) => {
  const date = useMemo(() => formatDate(new Date()), []);

  return (
    <pre className={clsx(styles.contactFormCode, className)}>
      <code className={styles.code}>
        <Const /> button = <Global>document</Global>.
        <Method>querySelector</Method>(
        <String>
          '<Selector>#sendBtn</Selector>'
        </String>
        )<Semicolon />
        <br />
        <br />
        <Const /> message = {'{'}
        <br />
        {'\t'}
        <Field>name</Field>: <String>'{name.trim()}'</String>
        <Comma />
        <br />
        {'\t'}
        <Field>email</Field>: <String>'{email.trim()}'</String>
        <Comma />
        <br />
        {'\t'}
        <Field>message</Field>:{' '}
        <String>`{message.replace(/\n/g, '\n\t').trim()}`</String>
        <Comma />
        <br />
        {'\t'}
        <Field>date</Field>: <String>'{date}'</String>
        <Comma />
        <br />
        {'}'}
        <Semicolon />
        <br />
        <br />
        button.<Method>addEventListener</Method>(<String>'click'</String>
        <Comma /> {'() => {'} <br />
        {'\tform'}.<Method>send</Method>(message)
        <Semicolon />
        <br />
        {'})'}
        <Semicolon />
      </code>
    </pre>
  );
};

interface PieceProps {
  children: React.ReactNode | string;
}

const Const = () => <span className={styles.keyword}>const</span>;
const Semicolon = () => <span className={styles.comma}>;</span>;
const Comma = () => <span className={styles.comma}>,</span>;

const Global = ({ children }: PieceProps) => (
  <span className={styles.global}>{children}</span>
);

const Field = ({ children }: PieceProps) => (
  <span className={styles.field}>{children}</span>
);

const Method = ({ children }: PieceProps) => (
  <span className={styles.method}>{children}</span>
);

const String = ({ children }: PieceProps) => (
  <span className={styles.string}>{children}</span>
);

const Selector = ({ children }: PieceProps) => (
  <span className={styles.selector}>{children}</span>
);
