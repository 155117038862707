import React from 'react';
import './index.scss';
import { createRoot } from 'react-dom/client';
import { AlertContextProvider } from './contexts/alert';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <AlertContextProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </AlertContextProvider>,
);
