import React from 'react';
import styles from './style.scss';
import clsx from 'clsx';

interface LeftPanelProps {
  children: React.ReactNode;
  className?: string;
}

export const ContentBody = ({ children, className }: LeftPanelProps) => {
  return <div className={clsx(styles.contentBody, className)}>{children}</div>;
};
