import React from 'react';
import { SvgProps } from '../SvgProps';
import { appColors } from '../../../utils/constants';

export const ArrowOutlineIcon = ({
  className,
  color = appColors.text,
  onClick,
}: SvgProps) => (
  <svg
    width='13'
    height='9'
    viewBox='0 0 13 9'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    fill={color}
    onClick={onClick}
  >
    <path d='M6.364 5.27715L11.314 0.327148L12.728 1.74115L6.364 8.10515L0 1.74115L1.414 0.327148L6.364 5.27715Z' />
  </svg>
);
