import React, { useEffect, useMemo, useRef } from 'react';
import styles from './style.scss';
import { Project, ProjectDescription, projectsTagsAsArray } from '../../utils';
import { Button } from '../../../../controls/button';
import { textToComponents } from '../../../about/commentTextBlock';

interface BodyProps {
  project: Project;
}

export const ProjectDialogBody = ({ project }: BodyProps) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTo({ top: 0, left: 0 });
    }
  }, [project]);

  return (
    <>
      <div className={styles.projectDialogHeader}>
        <div className={styles.imageWrapper}>
          <img
            src={project.image}
            alt='project-image'
            className={styles.image}
          />
        </div>
        <h2 className={styles.title}>{project.fullName}</h2>
      </div>
      <div className={styles.projectDialogContent} ref={ref}>
        <ul className={styles.tags}>
          {project.tagsIds.map((tId) => (
            <Tag tagId={tId} key={tId} />
          ))}
        </ul>
        <Description data={project.description} />
      </div>
      {project.footerLinks && (
        <div className={styles.projectDialogFooter}>
          {project.footerLinks.map((link, i) => (
            <Button link={link.url} color='secondary' key={i}>
              {link.title}
            </Button>
          ))}
        </div>
      )}
    </>
  );
};

interface TagProps {
  tagId: string;
}

const Tag = ({ tagId }: TagProps) => {
  const tag = useMemo(
    () => projectsTagsAsArray.find((t) => t.id === tagId),
    [tagId],
  );

  if (!tag) return null;

  return (
    <li className={styles.tag}>
      <tag.icon className={styles.icon} />
      {tag.title}
    </li>
  );
};

interface DescriptionProps {
  data: ProjectDescription;
}

const Description = React.memo(({ data }: DescriptionProps) => {
  return (
    <div className={styles.description}>
      {data.map((el, i) => {
        switch (el.type) {
          case 'header':
            return (
              <h4 key={i} className={styles.heading}>
                {el.value}
              </h4>
            );
          case 'text':
            return (
              <p key={i} className={styles.text}>
                {textToComponents(el.value)}
              </p>
            );
          case 'image':
            return (
              <div key={i} className={styles.imageBlock}>
                <img src={el.url} alt={el.label} className={styles.image} />
                <p className={styles.hint}>{el.label}</p>
              </div>
            );
          case 'list':
            return (
              <ul key={i} className={styles.list}>
                {el.value.map((line, j) => (
                  <li key={`${i}-${j}`}>{line}</li>
                ))}
              </ul>
            );
          default:
            return null;
        }
      })}
    </div>
  );
});
