import React from 'react';
import styles from './style.scss';
import { TelegramIcon } from '../../../svg/icons/Telegram';
import { LinkedInIcon } from '../../../svg/icons/LinkedIn';
import { MailIcon } from '../../../svg/icons/Mail';
import { InstagramIcon } from '../../../svg/icons/Instagram';
import { SvgProps } from '../../../svg/SvgProps';
import { WhatsAppIcon } from '../../../svg/icons/WhatsApp';

type Contact = {
  url: string;
  Icon: React.FunctionComponent<SvgProps>;
  title: string;
};

const primaryContacts: Contact[] = [
  {
    url: 'https://t.me/the_gregr',
    Icon: TelegramIcon,
    title: 't.me/the_gregr',
  },
  {
    url: 'mailto:thegreg.dev@gmail.com',
    Icon: MailIcon,
    title: 'thegreg.dev@gmail.com',
  },
];

const secondaryContacts: Contact[] = [
  /* {
      url: 'https://www.upwork.com/freelancers/~014a5e181e1d9b9ae9?s=1110580755107926016',
      Icon: UpworkIcon,
      title: 'upwork-profile',
    },*/
  {
    url: 'https://linkedin.com/in/thegregr',
    Icon: LinkedInIcon,
    title: 'linkedin-profile',
  },
  {
    url: 'https://wa.me/79655788853',
    Icon: WhatsAppIcon,
    title: 'whatsapp-contact',
  },
  {
    Icon: InstagramIcon,
    url: 'https://instagram.com/the.gregr/',
    title: 'instagram-account',
  },
];

interface Props {
  mode: 'primary' | 'secondary';
}

export const ContactsLeftPanel = ({ mode }: Props) => {
  return (
    <ul className={styles.contactsLeftPanel}>
      {(mode === 'primary' ? primaryContacts : secondaryContacts).map(
        (contact, i) => (
          <ContactLine data={contact} key={i} />
        ),
      )}
    </ul>
  );
};

interface ContactLineProps {
  data: Contact;
}

const ContactLine = ({ data }: ContactLineProps) => (
  <li>
    <a href={data.url} className={styles.contactLine} target='_blank'>
      <div className={styles.iconWrapper}>
        <data.Icon className={styles.icon} />
      </div>
      <div className={styles.text}>{data.title}</div>
    </a>
  </li>
);
