import React from 'react';
import styles from './style.scss';
import clsx from 'clsx';
import { appColors } from '../../utils/constants';

interface Props {
  size?: number;
  color?: string;
  className?: string;
}

export const Loader = ({
  size = 40,
  color = appColors.textActive,
  className,
}: Props) => {
  return (
    <div
      className={clsx(styles.loaderWrapper, className)}
      style={{ width: size, height: size }}
    >
      <div className={styles.loader}>
        <div
          className={styles.loaderInner}
          style={{
            border: `${size * 0.075}px solid`,
            borderColor: `${color} transparent`,
            width: size * 0.8,
            height: size * 0.8,
          }}
        />
      </div>
    </div>
  );
};
