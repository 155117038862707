import React from 'react';
import styles from './style.scss';
import { ContentWrapper } from '../../layout/content/wrapper';
import { ContentLeftPanel } from '../../layout/content/leftPanel';
import { ContentLeftPanelSection } from '../../layout/content/leftPanel/section';
import { ContactsLeftPanel } from './leftPanelContacts';
import { ContactPageBody } from './contactPageBody';

export const ContactsPage = () => {
  return (
    <ContentWrapper>
      <ContentLeftPanel scrollable className={styles.contactsLeftPanel}>
        <ContentLeftPanelSection title={'contacts'}>
          <ContactsLeftPanel mode='primary' />
        </ContentLeftPanelSection>
        <ContentLeftPanelSection title={'find-me-also-in'}>
          <ContactsLeftPanel mode='secondary' />
        </ContentLeftPanelSection>
      </ContentLeftPanel>
      <ContactPageBody />
    </ContentWrapper>
  );
};
