import React from 'react';
import styles from './style.scss';
import clsx from 'clsx';
import { InputState } from './utils';

// these types may look weird but with this Input can be used both manually
// and in a short way, with useInputState hook
type WrappedState = { state: InputState };
type WithState = InputState | WrappedState;

type InputProps = Partial<InputState> & {
  onChange: (newValue: string) => any;
  className?: string;
  placeholder?: string;
  onEnterPress?: () => any;
  autoFocus?: boolean;
  type?: 'text' | 'password' | 'number' | 'numberInt';
  disabled?: boolean;
  id?: string;
  label?: string;
  labelClassName?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  onFocus?: () => any;
  onBlur?: () => any;
  multi?: boolean;
} & WithState;

export const Input = (props: InputProps) => {
  // destructuring common props
  const {
    onChange,
    className = '',
    autoFocus = false,
    placeholder = undefined,
    onEnterPress,
    type = 'text',
    disabled = false,
    id,
    label,
    labelClassName,
    inputRef,
    onFocus,
    onBlur,
    multi,
  } = props;

  // destructuring specific props
  const {
    value,
    valid = true,
    hint: rawHint,
  } = 'state' in props ? props.state : props;

  // transforming hint to array of strings from any type
  const hint = transformHint(rawHint);

  const handleChange = (e: any) => {
    const newValue = e.target.value;
    if (type === 'number' && !multi) {
      if (newValue !== '' && !numeric.test(newValue)) return;
    }
    if (type === 'numberInt' && !multi) {
      if (newValue !== '' && !integerNumeric.test(newValue)) return;
    }
    onChange(newValue);
  };

  const onKeyDown = (
    e: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    if (onEnterPress && e.key === 'Enter') {
      if (multi && !e.ctrlKey && !e.metaKey) {
        return;
      }
      onEnterPress();
      e.preventDefault();
      e.stopPropagation();
    }
  };

  return (
    <div
      className={clsx(styles.inputWrapper, !valid && styles.invalid, className)}
    >
      {label && (
        <div className={clsx(styles.label, labelClassName)}>{label}</div>
      )}
      {React.createElement(multi ? 'textarea' : 'input', {
        id,
        value,
        onChange: handleChange,
        onKeyDown,
        className: clsx(styles.input, disabled && styles.disabled),
        autoFocus,
        placeholder,
        disabled,
        type: type === 'number' ? 'text' : type,
        ref: inputRef,
        onFocus,
        onBlur,
        rows: multi ? 4 : undefined,
      })}
      {hint.length > 0 && <div className={styles.hint}>{hint[0]}</div>}
    </div>
  );
};

const numeric = new RegExp(/^\d+[.,]?\d*$/);
const integerNumeric = new RegExp(/^\d+$/);

const transformHint = (rawHint: string | string[] | undefined): string[] => {
  if (!rawHint) return [];
  if (typeof rawHint === 'string') return [rawHint];
  return rawHint;
};
