import React from 'react';
import { SvgProps } from '../SvgProps';
import { appColors } from '../../../utils/constants';

export const EthereumIcon = ({
  className,
  color = appColors.text,
  onClick,
}: SvgProps) => (
  <svg viewBox='0 0 32 32' fill={color} className={className} onClick={onClick}>
    <path d='M15.927 23.959l-9.823-5.797 9.817 13.839 9.828-13.839-9.828 5.797zM16.073 0l-9.819 16.297 9.819 5.807 9.823-5.801z' />
  </svg>
);
