import React from 'react';
import { SvgProps } from '../SvgProps';
import { appColors } from '../../../utils/constants';

export const MailIcon = ({
  className,
  color = appColors.text,
  onClick,
}: SvgProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    x='0px'
    y='0px'
    width='100'
    height='100'
    viewBox='0 0 30 30'
    className={className}
    fill={color}
    onClick={onClick}
  >
    <path d='M 4 5 C 2.9069372 5 2 5.9069372 2 7 L 2 23 C 2 24.093063 2.9069372 25 4 25 L 26 25 C 27.093063 25 28 24.093063 28 23 L 28 7 C 28 5.9069372 27.093063 5 26 5 L 4 5 z M 6.6992188 7 L 23.300781 7 L 15 13.134766 L 6.6992188 7 z M 5 9.4746094 L 15 16.865234 L 25 9.4746094 L 25 23 L 5 23 L 5 9.4746094 z'></path>
  </svg>
);
