import React from 'react';
import styles from './style.scss';
import clsx from 'clsx';
import { CloseIcon } from '../svg/icons/Close';
import { appColors } from '../../utils/constants';

interface DialogProps {
  opened: boolean;
  onClose: () => void;
  children: React.ReactNode;
  className?: string;
}

export const Dialog = ({
  opened,
  onClose,
  children,
  className,
}: DialogProps) => {
  const handleDialogClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <div
      className={clsx(styles.dialogWrapper, opened && styles.visible)}
      onClick={onClose}
    >
      <div
        className={clsx(styles.dialog, className)}
        onClick={(e) => handleDialogClick(e)}
      >
        <CloseIcon
          className={styles.closeButton}
          onClick={onClose}
          color={appColors.textActive}
        />
        {children}
      </div>
    </div>
  );
};
