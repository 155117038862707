import React from 'react';
import styles from './style.scss';
import clsx from 'clsx';

interface LeftPanelProps {
  children: React.ReactNode;
  scrollable?: boolean;
  className?: string;
}

export const ContentLeftPanel = ({
  children,
  scrollable,
  className,
}: LeftPanelProps) => {
  return (
    <div
      className={clsx(
        styles.leftPanel,
        scrollable && styles.scrollable,
        className,
      )}
    >
      {children}
    </div>
  );
};
