import React, { useEffect, useMemo, useRef, useState } from 'react';
import styles from './style.scss';
import clsx from 'clsx';
import useWindowSize from '../../../../hooks/useWindowSize';
import { textTemplates } from '../utils';

interface CommentTextBlockProps {
  text: string;
  disableDecorations?: boolean;
  className?: string;
}

export const CommentTextBlock = ({
  text,
  disableDecorations,
  className,
}: CommentTextBlockProps) => {
  const [sizeX] = useWindowSize();
  const mainTextRef = useRef<HTMLParagraphElement>(null);
  const referenceRef = useRef<HTMLParagraphElement>(null);
  const [lines, setLines] = useState<number[]>([]);

  useEffect(() => {
    // there is a main <p> element, and also
    // a hidden <p> element with just 1 line
    const element = mainTextRef.current;
    const refElement = referenceRef.current;
    if (element && refElement) {
      const height = element.offsetHeight;
      // we take hidden <p> element height as line-height
      const lineHeight = refElement.offsetHeight;
      // filling lines array like this: [1,2,...,n] where n - lines amount
      if (lineHeight === 0) {
        setLines([]);
      } else {
        setLines(
          [...Array(Math.round(height / lineHeight))].map((_, i) => i + 1),
        );
      }
    }
  }, [sizeX, text]);

  const processedText = useMemo(() => textToComponents(text), [text]);

  return (
    <div className={clsx(styles.commentTextBlock, className)}>
      <div
        className={clsx(
          styles.lineNumbers,
          disableDecorations && styles.noDecorations,
        )}
      >
        <ul className={styles.column}>
          {lines.map((el) => (
            <li key={el}>{el}</li>
          ))}
        </ul>
        <ul className={styles.column}>
          {lines.map((el, i) => {
            if (i === 0) return <li key={el}>/**</li>;
            if (i === lines.length - 1) return <li key={el}>&nbsp;*/</li>;
            return <li key={el}>&nbsp;*&nbsp;</li>;
          })}
        </ul>
      </div>
      <p ref={mainTextRef} className={styles.commentText}>
        <br />
        {processedText}
        <br />
        <br />
      </p>
      <p className={styles.reference} ref={referenceRef}>
        <br />
      </p>
    </div>
  );
};

export const textToComponents = (input: string): React.ReactNode[] => {
  let components: React.ReactNode[] = [input];

  textTemplates.forEach(({ regex, replacement }) => {
    const oldC = [...components];
    components = [];
    oldC.forEach((piece) => {
      if (typeof piece !== 'string') {
        components.push(piece);
      } else {
        let currentIndex = 0;
        let matches: RegExpMatchArray | null;
        while ((matches = regex.exec(piece)) !== null) {
          const [entry, ...params] = matches;
          components.push(piece.substring(currentIndex, matches.index));
          currentIndex = (matches.index || 0) + entry.length;
          components.push(replacement(...params));
        }
        const remainingText = piece.substring(currentIndex);
        if (remainingText) {
          components.push(remainingText);
        }
      }
    });
    components = components.filter(
      (c) => typeof c !== 'string' || c.trim().length > 0,
    );
  });

  return components.map((el, i) => {
    if (typeof el === 'string') {
      return <React.Fragment key={`plain-text-${i}`}>{el}</React.Fragment>;
    }
    return el;
  });
};
