type Config = {
  [key: string]: string;
};

class LocalStorageHandler {
  private readonly configName: string;

  constructor(name: string) {
    this.configName = name;
  }

  private getConfig = (): Config => {
    const raw: string = localStorage.getItem(this.configName) || '{}';
    return JSON.parse(raw) || {};
  };

  private setConfig = (newConfig: Config) => {
    localStorage.setItem(this.configName, JSON.stringify(newConfig));
  };

  private getItem = (key: string): string | undefined => {
    const config = this.getConfig();
    return config[key];
  };

  private setItem = (key: string, value: string) => {
    const config = this.getConfig();
    config[key] = value;
    this.setConfig(config);
  };

  public getOpenedFolders = (): string[] => {
    const raw = this.getItem('aboutOpenedFoldersIds') || '[]';
    let result: any[] = [];
    try {
      result = JSON.parse(raw);
      if (Array.isArray(result)) {
        result = result.filter((el) => typeof el === 'string');
      } else {
        result = [];
      }
    } catch (e) {
      result = [];
    }
    return result;
  };

  public handleFolderOpen = (folderId: string) => {
    const prev = this.getOpenedFolders();
    this.setItem(
      'aboutOpenedFoldersIds',
      JSON.stringify(prev.concat(folderId)),
    );
  };

  public handleFolderClose = (folderId: string) => {
    const prev = this.getOpenedFolders();
    this.setItem(
      'aboutOpenedFoldersIds',
      JSON.stringify(prev.filter((id) => id !== folderId)),
    );
  };

  public clear = () => {
    this.setConfig({});
  };
}

export const storage = new LocalStorageHandler('TheGreg');
