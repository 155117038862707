// noinspection JSUnusedGlobalSymbols

type ResponseOK<T> = {
  success: true;
  data: T;
};

type ResponseErr = {
  success: false;
  error: any;
};

type Response<T> = ResponseOK<T> | ResponseErr;

type Sender = () => Promise<globalThis.Response>;

abstract class APIFetcher {
  private headers: { [key: string]: string } = {};

  private processRequest = <T>(sender: Sender): Promise<T> => {
    return new Promise<T>((resolve, reject) => {
      sender()
        .then((r) => {
          return new Promise<Response<T>>((resolve, reject) => {
            const status = r.status;
            r.text()
              .then((text) => {
                const json = text.length > 0 ? JSON.parse(text) : undefined;
                if (status !== 200 && status !== 304) {
                  resolve({ success: false, error: json });
                } else {
                  resolve({ success: true, data: json });
                }
              })
              .catch((err) => reject(err));
          });
        })
        .then((response: Response<T>) => {
          if (response.success) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((err) => reject(err));
    });
  };

  protected get = <T>(url: string, params?: any): Promise<T> => {
    const finalUrl = params
      ? `${url}?${new URLSearchParams(params).toString()}`
      : url;
    const sender: Sender = () => {
      return fetch(finalUrl, {
        method: 'GET',
        headers: this.headers,
      });
    };
    return this.processRequest<T>(sender);
  };

  protected post = <T>(url: string, payload?: any): Promise<T> => {
    const sender: Sender = () => {
      return fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': ' application/json',
          ...this.headers,
        },
        body: JSON.stringify(payload),
      });
    };
    return this.processRequest<T>(sender);
  };

  public setTokenHeader = (token: string) => {
    this.headers = { 'x-auth': token };
  };

  public clearHeaders = () => {
    this.headers = {};
  };
}

class ContactsFetcher extends APIFetcher {
  public sendEnquiry = (data: {
    name: string;
    email: string;
    message: string;
  }) => {
    return this.post('/api/contact/sendenquiry', { enquiry: data });
  };
}

class Fetcher {
  private readonly _contact: ContactsFetcher;

  constructor() {
    this._contact = new ContactsFetcher();
  }

  get contact(): ContactsFetcher {
    return this._contact;
  }
}

export const fetcher = new Fetcher();
