import React from 'react';
import styles from './style.scss';
import { LinkedInIcon } from '../../../svg/icons/LinkedIn';
import { TelegramIcon } from '../../../svg/icons/Telegram';
import { MailIcon } from '../../../svg/icons/Mail';
import clsx from 'clsx';

const socials = [
  { url: 'https://t.me/the_gregr', Icon: TelegramIcon },
  { url: 'https://linkedin.com/in/thegregr', Icon: LinkedInIcon },
  { url: 'mailto:thegreg.dev@gmail.com', Icon: MailIcon },
];

interface Props {
  className?: string;
}

export const AppFooter = ({ className }: Props) => {
  return (
    <footer className={clsx(styles.appFooter, className)}>
      <div className={styles.leftSide}>find me in:</div>
      <nav className={styles.navigation}>
        {socials.map((s, i) => (
          <a href={s.url} target='_blank' key={i} className={styles.link}>
            <s.Icon className={styles.icon} />
          </a>
        ))}
      </nav>
    </footer>
  );
};
